<template>
  <div class="max-w-xl mx-auto text-center py-8 h-3/4-screen">
    <h1 class="my-2 text-gray-900">Register</h1>
    <Register />
  </div>
</template>

<script>
import Register from '@/components/Register';
export default {
  data: function () {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
  },
  watch: {
    user() {
      this.$router.push('/checkout/summary');
    },
  },
  mounted() {},
  methods: {
    next() {
      this.$router.push({ path: '/checkout/summary' });
    },
  },
  components: {
    Register,
  },
};
</script>
<style scoped></style>

<template>
  <div class="md:p-16">
    <div class="bg-white py-8 px-4 text-center">
      <div v-if="order">
        <h2>Thank you {{ order.name }}!</h2>
        <div class="my-4">
          A confirmation email has been sent to <strong>{{ order.email }}</strong>
        </div>
        <router-link v-if="admin" :to="`/admin/orders/${order._id}`" class="my-4">
          <strong>Order Number:</strong> #{{ order.orderNumber }}
        </router-link>

        <div v-else class="my-4"><strong>Order Number:</strong> #{{ order.orderNumber }}</div>

        <div class="text-center w-full">
          <img src="/images/static/order.svg" class="w-64 h-64 mx-auto" alt="" />
        </div>
      </div>
      <div v-else class="text-center">
        <h3>Your checkout is completed.</h3>
        <img class="w-96 my-6 mx-auto" src="/images/static/empty.png" />
      </div>
      <div class="grid grid-cols-2 my-4">
        <div>
          <router-link tag="button" class="btn-primary rounded" to="/user">
            See Order History
          </router-link>
        </div>
        <div>
          <router-link tag="button" class="btn-action rounded" to="/">
            Continue Shopping
          </router-link>
        </div>
      </div>
    </div>
    <div class="carousel-wrapper">
      <carousel :products="suggestedItems" :white="false" :loading="suggestedLoading" />
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/items/ProductCarousel';

export default {
  data: function () {
    return {};
  },
  computed: {
    order() {
      return this.$store.getters['order/getMostRecentPurchase'];
    },
    suggestedItems() {
      return this.$store.getters['products/getSuggested'];
    },
    admin() {
      return this.$store.getters.getAuthorized;
    },
    suggestedLoading() {
      return this.$store.getters['products/getSuggestedLoading'];
    },
  },
  watch: {},
  mounted() {},
  methods: {},
  components: {
    Carousel,
  },
};
</script>
<style scoped>
/* For anything larger than mobile device */
@media only screen and (min-width: 600px) {
  .carousel-wrapper::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #edf2f7);
    width: 15px;
  }

  .carousel-wrapper::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #edf2f7);
    width: 15px;
  }
}
</style>

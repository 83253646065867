<template>
  <div class="bg-gray-50">
    <div class="max-w-2xl mx-auto pt-4 md:pt-16 pb-24 px-4 sm:px-6 lg:max-w-6xl lg:px-8">
      <h2 class="sr-only">Checkout</h2>

      <div class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16 flex flex-col-reverse">
        <form @submit.prevent="routePaymentOption">
          <div>
            <h2 class="text-lg font-medium text-gray-900">Contact information</h2>

            <div class="mt-4">
              <label for="email-address" class="block text-sm font-medium text-primary">
                Email address
              </label>
              <div class="mt-1">
                <input
                  type="email"
                  id="email-address"
                  name="email-address"
                  autocomplete="email"
                  required
                  v-model="order.email"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div class="sm:col-span-2 mt-4" v-if="commercial">
              <label for="name" class="text-gray-600 text-sm flex">
                Business Name
                <div class="z-1 cursor-pointer text-primary hover:text-primary-light tooltip mx-2">
                  <font-awesome-icon class="text-sm" icon="info-circle" />
                  <span
                    class="tooltiptext-right rounded shadow text-black mx-1 flex flex-col w-96 text-left p-2"
                  >
                    You can only change your business name inside of your profile settings.
                  </span>
                </div>
              </label>
              <div class="mt-1 text-gray-600">
                {{ order.businessName }}
              </div>
            </div>
            <div class="mt-2" v-if="commercial">
              <label for="secondary-email-address" class="text-sm text-primary flex">
                <span class="text-primary text-sm font-medium"> Secondary Email </span>
                <div class="z-1 cursor-pointer text-primary hover:text-primary-light tooltip mx-2">
                  <font-awesome-icon class="text-sm" icon="info-circle" />
                  <span
                    class="tooltiptext-right rounded shadow text-black mx-1 flex flex-col w-96 text-left p-2"
                    >A copy of the confirmation email will be sent to this email.
                  </span>
                </div>
                <span class="text-gray-500">(optional)</span>
              </label>
              <div class="mt-1">
                <input
                  type="email"
                  id="secondary-email-address"
                  name="secondary-email-address"
                  autocomplete="email"
                  v-model="order.secondaryEmail"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div class="grid grid-cols-2 gap-4 mt-2" v-if="commercial">
              <div class="flex flex-col my-2">
                <label for="purchase-order" class="text-xs text-gray-500 font-medium">
                  Purchase Order
                </label>
                <input
                  type="text"
                  id="purchase-order"
                  class="rounded border-gray-200 border p-1 bg-white"
                  v-model="order.purchaseOrder"
                />
              </div>
              <div class="flex flex-col my-2">
                <label for="job-name" class="text-xs text-gray-500 font-medium"> Job Name </label>
                <input
                  type="text"
                  class="rounded border-gray-200 border p-1 bg-white"
                  id="job-name"
                  v-model="order.orderName"
                />
              </div>
            </div>
            <div class="mt-2">
              <label for="note" class="text-primary text-sm font-medium">Note</label>
              <textarea
                name=""
                class="w-full rounded border-gray-200 border p-1 bg-white"
                id="note"
                rows="3"
                v-model="order.customerNote"
              />
            </div>
            <div class="flex mt-2 justify-between items-end md:items-center">
              <div
                class="flex flex-col"
                :class="
                  couponStatus == 'success'
                    ? 'text-green-600'
                    : !couponStatus
                    ? 'text-primary'
                    : 'text-red'
                "
              >
                <label for="coupon-code" class="text-sm font-medium"> Coupon Code </label>
                <input
                  type="text"
                  class="rounded border-gray-200 border p-1 bg-white"
                  :class="
                    couponStatus == 'success'
                      ? '  border-green-600 '
                      : !couponStatus
                      ? ' '
                      : '  border-red-600'
                  "
                  id="coupon-code"
                  v-model="order.couponCode"
                />
                <span v-if="couponStatus && couponStatus != 'success'" class="text-xs">
                  {{ couponStatus }}
                </span>
              </div>
              <div v-if="couponStatus != 'success'">
                <button class="btn-primary rounded" type="button" @click="verifyCoupon()">
                  apply
                </button>
              </div>
            </div>
          </div>

          <div class="mt-10 border-t border-gray-400 pt-10 grid grid-cols-4 gap-4">
            <h2 class="text-lg font-medium text-gray-900 col-span-4 flex">
              Shipping information
              <div class="z-1 cursor-pointer text-primary hover:text-primary-light tooltip mx-2">
                <font-awesome-icon class="text-sm" icon="info-circle" />
                <span
                  class="tooltiptext-right text-sm shadow text-black mx-1 flex flex-col w-96 text-left p-2 rounded"
                >
                  We can only ship to the contiguous US.
                </span>
              </div>
            </h2>
            <div class="col-span-4 gap-y-6">
              <div>
                <label for="first-name" class="block text-sm font-medium text-primary">Name</label>
                <div class="mt-1">
                  <input
                    type="text"
                    id="first-name"
                    name="first-name"
                    autocomplete="given-name"
                    required
                    v-model="order.name"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>

            <div class="col-span-4">
              <label for="address" class="block text-sm font-medium text-primary">Address</label>
              <div class="mt-1">
                <input
                  type="text"
                  name="address"
                  id="address"
                  v-model="order.shippingAddress"
                  required
                  autocomplete="street-address"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div class="col-span-3">
              <label for="city" class="block text-sm font-medium text-primary">City</label>
              <div class="mt-1">
                <input
                  type="text"
                  name="city"
                  id="city"
                  autocomplete="address-level2"
                  required
                  v-model="order.shippingCity"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div class="col-span-1">
              <label for="region" class="block text-sm font-medium text-primary">State</label>
              <div class="mt-1">
                <select
                  v-model="order.shippingState"
                  name="region"
                  id="region"
                  autocomplete="address-level1"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                  required
                >
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AR">AR</option>
                  <option value="AZ">AZ</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DC">DC</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="IA">IA</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="MA">MA</option>
                  <option value="MD">MD</option>
                  <option value="ME">ME</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MO">MO</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="NC">NC</option>
                  <option value="NE">NE</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NV">NV</option>
                  <option value="NY">NY</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WI">WI</option>
                  <option value="WV">WV</option>
                  <option value="WY">WY</option>
                </select>
              </div>
            </div>

            <div class="col-span-4 sm:col-span-2">
              <label for="postal-code" class="block text-sm font-medium text-primary">
                Postal code
              </label>
              <div class="mt-1">
                <input
                  type="text"
                  name="postal-code"
                  id="postal-code"
                  autocomplete="postal-code"
                  @change="shippingMethodKey++"
                  v-model="order.shippingZip"
                  required
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div class="col-span-4 sm:col-span-2">
              <label for="phone" class="block text-sm font-medium text-primary">Phone</label>
              <div class="mt-1">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autocomplete="tel"
                  v-model="order.phone"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div class="flex flex-col my-2 lg:my-0 col-span-4" v-if="commercial">
              <label for="requested-delivery" class="text-primary text-sm">
                Requested Delivery Date
              </label>
              <input
                type="date"
                name=""
                class="block w-full border-gray-300 rounded-md shadow-sm mt-1 focus:border-blue-500 sm:text-sm"
                id="requested-delivery"
                v-model="order.deliveryDate"
              />
            </div>
          </div>

          <!-- Start of billing -->
          <div class="mt-10 border-t border-gray-400 pt-10 grid md:grid-cols-4 gap-4">
            <h2 class="text-lg font-medium text-gray-900 col-span-4 sm:col-span-2">
              Billing information
            </h2>
            <div class="flex items-center -mt-4 sm:mt-0 col-span-4 sm:col-span-2">
              <input
                id="same-as-shipping"
                name="same-as-shipping"
                type="checkbox"
                v-model="same"
                @change="changeSame"
                class="h-4 w-4 border-gray-300 rounded border text-blue-600"
              />
              <div class="ml-2">
                <label for="same-as-shipping" class="text-sm font-medium text-gray-900">
                  Same as shipping information
                </label>
              </div>
            </div>

            <div class="col-span-4 gap-y-6" v-if="!same">
              <div>
                <label for="first-name" class="block text-sm font-medium text-primary">Name</label>
                <div class="mt-1">
                  <input
                    type="text"
                    id="first-name"
                    name="first-name"
                    autocomplete="given-name"
                    required
                    v-model="order.name"
                    class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>

            <div class="col-span-4" v-if="!same">
              <label for="address" class="block text-sm font-medium text-primary">Address</label>
              <div class="mt-1">
                <input
                  type="text"
                  name="address"
                  id="address"
                  v-model="order.billingAddress"
                  required
                  autocomplete="street-address"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div v-if="!same" class="col-span-3">
              <label for="city" class="block text-sm font-medium text-primary">City</label>
              <div class="mt-1">
                <input
                  type="text"
                  name="city"
                  id="city"
                  autocomplete="address-level2"
                  required
                  v-model="order.billingCity"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div v-if="!same" class="col-span-1">
              <label for="region" class="block text-sm font-medium text-primary">State </label>
              <div class="mt-1">
                <select
                  v-model="order.billingState"
                  name="region"
                  id="region"
                  autocomplete="address-level1"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                  required
                >
                  <option value="AL">AL</option>
                  <option value="AR">AR</option>
                  <option value="AZ">AZ</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DC">DC</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="IA">IA</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="MA">MA</option>
                  <option value="MD">MD</option>
                  <option value="ME">ME</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MO">MO</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="NC">NC</option>
                  <option value="NE">NE</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NV">NV</option>
                  <option value="NY">NY</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WI">WI</option>
                  <option value="WV">WV</option>
                  <option value="WY">WY</option>
                </select>
              </div>
            </div>

            <div v-if="!same" class="col-span-4">
              <label for="postal-code" class="block text-sm font-medium text-primary">
                Postal code
              </label>
              <div class="mt-1">
                <input
                  type="text"
                  name="postal-code"
                  id="postal-code"
                  autocomplete="postal-code"
                  required
                  v-model="order.billingZip"
                  class="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
          </div>

          <div class="mt-10 border-t border-gray-400 pt-10">
            <fieldset>
              <legend class="text-lg font-medium text-gray-900">Delivery method</legend>
              <div class="mt-4 flex flex-wrap gap-2 sm:gap-x-4" :key="shippingMethodKey">
                <div v-for="method in deliveryMethods" :key="method.id">
                  <button
                    class="relative bg-white border rounded-lg shadow-sm p-2 flex cursor-pointer focus:outline-none"
                    :class="
                      isCheckedDelivery(method.value)
                        ? 'border border-primary bg-action text-white'
                        : 'border-gray-300 text-gray-900'
                    "
                    v-if="allowShipping || method.alwaysAllow"
                    @click="setDelivery(method.value)"
                    type="button"
                  >
                    <span class="flex-1 flex">
                      <span class="flex flex-col">
                        <span
                          :id="`delivery-method-${method.id}-label`"
                          class="block text-sm font-medium"
                        >
                          {{ method.title }}
                        </span>
                        <span
                          :id="`delivery-method-${method.id}-description`"
                          class="mt-1 flex items-center text-sm"
                          :class="
                            isCheckedDelivery(method.value) ? ' text-gray-200' : 'text-gray-500'
                          "
                        >
                          {{ method.turnaround }}
                        </span>
                        <span
                          :id="`delivery-method-${method.id}-price`"
                          class="mt-1 text-sm font-medium"
                        >
                          ${{ calculateShipping(method.value) }}
                        </span>
                      </span>
                    </span>

                    <svg
                      class="h-5 w-5 text-indigo-600"
                      :class="isCheckedDelivery(method) ? '' : 'hidden'"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span
                      class="absolute -inset-px rounded-lg border-2 pointer-events-none"
                      :class="
                        isCheckedDelivery(method) ? 'border border-blue-500' : 'border-transparent'
                      "
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
              </div>
            </fieldset>
          </div>

          <!-- Payment -->
          <div class="mt-10 border-t border-gray-400 pt-10">
            <h2 class="text-lg font-medium text-gray-900">Payment</h2>

            <fieldset class="mt-4">
              <legend class="sr-only">Payment type</legend>
              <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                <div v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
                  <div
                    v-if="
                      paymentMethod.title === 'Credit card'
                        ? true
                        : !commercial
                        ? false
                        : paymentMethod.title == 'Check'
                        ? true
                        : delayed
                        ? true
                        : false
                    "
                    class="flex items-center"
                  >
                    <input
                      :id="paymentMethod.id"
                      name="payment-type"
                      type="radio"
                      :value="paymentMethod.id"
                      :checked="paymentMethod.id === order.paymentType"
                      @click="setPaymentMethod(paymentMethod.id)"
                      class="h-4 w-4 text-blue-600 border-gray-300"
                    />
                    <label
                      :for="paymentMethod.id"
                      class="ml-3 block text-sm font-medium text-primary"
                    >
                      {{ paymentMethod.title }}
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>

            <div :key="shippingMethodKey">
              <div v-if="showCreditCard">
                <div v-if="loading" class="relative h-48">
                  <Loading :whiteSpace="false" text="processing..." />
                </div>
                <div id="app" class="rounded py-4" v-else>
                  <card
                    class="stripe-card my-2 mx-auto border border-gray-200 p-2 bg-white"
                    :class="{ complete }"
                    :stripe="stripeKey"
                    @change="complete = $event.complete"
                  />
                  <div v-if="error" class="text-red">{{ error }}</div>
                  <div class="flex justify-end">
                    <img src="/images/static/1.png" class="h-10 mx-2" alt="" />
                    <img src="/images/static/2.png" class="h-10 mx-2" alt="" />
                    <img src="/images/static/3.png" class="h-10 mx-2" alt="" />
                  </div>
                  <div class="">
                    <button
                      type="submit"
                      class="pay-with-stripe btn-action w-full rounded-full md:rounded my-4 mx-auto"
                    >
                      Confirm payment
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="mt-4">
                <button
                  class="btn-action w-full rounded-full md:rounded my-4 mx-auto"
                  type="submit"
                >
                  Place order
                </button>
              </div>
            </div>
          </div>
        </form>

        <!-- Order summary -->
        <div class="mb-4 lg:mt-0" :key="shippingMethodKey">
          <NewProductSummary
            :products="cart"
            :shipping="shippingCost"
            :discount="0"
            :taxRate="order.taxRate"
            :storeCredit="storeCredit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewProductSummary from './NewProductSummary.vue';
const zipCodes = require('@/assets/utils/zipcodes.js').default;
import Loading from '@/components/shared/Loading.vue';
import { Card, createToken } from 'vue-stripe-elements-plus';
import keys from '@/keys.json';
import calculateCosts from '@/assets/functions/calculateCosts';

export default {
  data: function () {
    return {
      deliveryMethods: [
        {
          id: 4,
          title: 'Fedex',
          value: 'fedex',
          turnaround: '4–10 business days',
          price: '$5.00',
          alwaysAllow: true,
        },
        {
          id: 2,
          title: 'Air',
          value: 'air',
          turnaround: '2–5 business days',
          price: '$16.00',
          alwaysAllow: true,
        },
        {
          id: 5,
          title: 'Local Delivery',
          value: 'local',
          turnaround: 'on requested date',
          price: 'Free',
          alwaysAllow: false,
        },
        {
          id: 6,
          title: 'Will Call',
          value: 'call',
          turnaround: 'on requested date',
          price: 'Free',
          alwaysAllow: false,
        },
      ],
      paymentMethods: [
        { id: 'credit-card', title: 'Credit card' },
        { id: 'terms', title: 'Terms' },
        { id: 'check', title: 'Check' },
      ],
      same: false,
      shippingMethodKey: 0,

      loading: false,
      stripeOptions: {},
      stripeKey: keys.STRIPE_KEY,
      stripeToken: null,
      shippingCost: 0,

      error: null,
      complete: false,
      storeCredit: 0,
      useStoreCredit: false,
    };
  },
  computed: {
    couponStatus() {
      return this.$store.getters['order/getCouponStatus'];
    },
    order() {
      return this.$store.getters['order/getOrder'];
    },
    user() {
      return this.$store.getters['order/getUser'];
    },
    delayed() {
      return this.$store.getters['order/getIsDelayedPayment'];
    },
    cart() {
      if (this.items != null) {
        return this.items;
      }
      return this.$store.getters['order/getCart'];
    },
    commercial() {
      if (this.$store.getters.getAuthorized) {
        return true;
      }
      return this.$store.getters.getCommercial;
    },
    orderTotal() {
      return this.$store.getters['order/getOrderTotal'];
    },
    mostRecentPurchase() {
      return this.$store.getters['order/getMostRecentPurchase'];
    },
    showCreditCard() {
      console.log(this.shippingMethodKey);
      if (this.order.paymentType === 'credit-card' || !this.order.paymentType) {
        return true;
      }
      return false;
    },
    costs: {
      get() {
        return calculateCosts(
          this.cart,
          this.order.taxRate,
          this.shippingCost,
          0,
          this.storeCredit
        );
      },
    },
    shippingCosts: {
      get() {
        return this.$store.getters['order/getShippingCosts'];
      },
    },
    allowShipping() {
      console.info(this.shippingMethodKey);
      if (!this.user) {
        return false;
      } else if (zipCodes.indexOf(parseInt(this.order.shippingZip)) > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    user() {
      this.$router.push('/checkout/summary');
    },
    mostRecentPurchase() {
      if (this.mostRecentPurchase) {
        this.$router.push({ path: '/checkout/thank-you' });
        this.$forceUpdate();
      }
    },
  },
  mounted() {
    this.verifyCoupon();
    this.order.orderName = '';
  },
  methods: {
    setUseStoreCredit() {
      if (this.useStoreCredit) {
        this.storeCredit =
          this.user.company.inStoreCredit > this.costs.total
            ? this.costs.total
            : this.user.company.inStoreCredit;
      } else {
        this.storeCredit = 0;
      }
    },
    calculateShipping(method) {
      const subtotal = this.orderTotal.subTotal * 1;
      switch (method) {
        case 'standard':
        case 'ground':
        case 'fedex':
          return subtotal < this.shippingCosts[0].price * 1
            ? this.shippingCosts[0].cost * 1
            : subtotal < this.shippingCosts[1].price * 1
            ? this.shippingCosts[1].cost * 1
            : subtotal < this.shippingCosts[2].price * 1
            ? this.shippingCosts[2].cost * 1
            : subtotal < this.shippingCosts[3].price * 1
            ? this.shippingCosts[3].cost * 1
            : this.shippingCosts[4].cost * 1;
        case 'air':
          return subtotal < this.shippingCosts[0].price * 1
            ? this.shippingCosts[0].cost * 2
            : subtotal < this.shippingCosts[1].price * 1
            ? this.shippingCosts[1].cost * 2
            : subtotal < this.shippingCosts[2].price * 1
            ? this.shippingCosts[2].cost * 2
            : subtotal < this.shippingCosts[3].price * 1
            ? this.shippingCosts[3].cost * 2
            : this.shippingCosts[4].cost * 2;
        case 'local':
        case 'call':
          return 0;
      }
    },
    routePaymentOption() {
      if (this.order.paymentType === 'credit-card') {
        this.pay();
      } else {
        this.placeOrder();
      }
    },
    placeOrder: async function () {
      this.order.status = 'Pending';
      if (!this.order.paymentType) {
        this.$store.commit('setError', {
          message: 'You must select a payment type.',
          color: 'red',
        });
        return;
      }
      if (this.stripeToken !== null || this.order.paymentType !== 'credit-card') {
        await this.$store.dispatch('order/submitOrder', {
          order: {
            ...this.costs,
            ...this.order,
          },
          stripeToken: this.stripeToken,
        });
      }

      if (!this.stripeToken && this.order.paymentType === 'credit-card') {
        this.$store.commit('setError', {
          message: 'Please enter a valid credit card in the payment section',
          color: 'red',
        });
      }
    },
    verifyCoupon() {
      this.$store.dispatch('order/getOrderTotal', this.order);
    },
    changeSame() {
      this.order.billingAddress = this.order.shippingAddress;
      this.order.billingCity = this.order.shippingCity;
      this.order.billingState = this.order.shippingState;
      this.order.billingZip = this.order.shippingZip;
    },
    setDelivery(method) {
      this.shippingMethodKey++;
      this.order.shippingMethod = method;
      this.order.shipping = this.calculateShipping(method);
      this.shippingCost = this.order.shipping;
    },
    isCheckedDelivery(method) {
      return this.order.shippingMethod == method;
    },
    async pay() {
      this.stripeOptions.name = this.order.name;
      this.stripeOptions.address_line1 = this.order.billingAddress;
      this.stripeOptions.address_city = this.order.billingCity;
      this.stripeOptions.address_state = this.order.billingState;
      this.stripeOptions.address_zip = this.order.billingZip;

      const response = await createToken(this.stripeOptions);
      if (response.token) {
        this.loading = true;
        this.stripeToken = response.token;
        this.placeOrder();
      } else {
        this.error = 'Credit Card validation has failed, please use a different card.';
      }
    },
    setPaymentMethod(method) {
      this.order.paymentType = method;
      this.shippingMethodKey++;
    },
  },
  components: { NewProductSummary, Card, Loading },
};
</script>

<template>
  <div class="bg-gray-200">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style></style>

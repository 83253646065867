<template>
  <div class="border-b border-primary overflow-hidden duration-200" id="shipping-wrapper">
    <div class="w-full relative flex cursor-pointer">
      <h4 class="text-xl">SHIPPING</h4>
    </div>
    <div class="md:grid md:grid-cols-3 gap-4">
      <div class="border-b border-gray-300 md:border-none pb-2 md:pb-0">
        <!-- User Info -->
        <div class="flex flex-col my-2">
          <label for="name" class="text-primary text-sm font-semibold">Name</label>
          <input
            type="text"
            id="name"
            class="rounded border-gray-200 border p-1 summary-input shipping-input bg-white"
            required
            v-model="order.name"
          />
        </div>
        <div class="flex flex-col my-2">
          <label for="name" class="text-gray-600 text-sm font-semibold flex">
            Business Name
            <div class="z-1 cursor-pointer text-primary hover:text-primary-light tooltip mx-2">
              <font-awesome-icon class="text-sm" icon="info-circle" />
              <span
                class="tooltiptext -left-6 -top-3 md:right-0 md:top-0 shadow text-black mx-1 flex flex-col w-96 text-left p-2"
              >
                You can only change business name on the edit user page.
              </span>
            </div>
          </label>
          <input
            type="text"
            id="businessName"
            disabled="true"
            class="rounded border-gray-200 border p-1 bg-white text-gray-600"
            v-model="order.businessName"
          />
        </div>
        <div class="flex flex-col my-2">
          <label for="email" class="text-primary text-sm font-semibold">Email</label>
          <input
            type="email"
            id="email"
            class="rounded border-gray-200 border p-1 summary-input shipping-input bg-white"
            required
            v-model="order.email"
          />
        </div>
        <div class="flex flex-col my-2">
          <label for="secondaryEmail" class="text-sm flex">
            <span class="text-primary font-semibold"> Secondary Email </span>
            <div class="z-1 cursor-pointer text-primary hover:text-primary-light tooltip mx-2">
              <font-awesome-icon class="text-sm" icon="info-circle" />
              <span
                class="tooltiptext-right shadow text-black mx-1 flex flex-col w-96 text-left p-2"
                >A copy of the confirmation email will be sent to this email.
              </span>
            </div>
            <span class="text-gray-500">(optional)</span>
          </label>
          <input
            type="email"
            id="secondaryEmail"
            autocomplete="off"
            class="rounded border-gray-200 border p-1 bg-white summary-input"
            v-model="order.secondaryEmail"
          />
        </div>
        <div class="flex flex-col w-full lg:mr-2">
          <label for="phone" class="text-primary text-sm font-semibold">Phone</label>
          <input
            type="tel"
            id="phone"
            class="rounded border-gray-200 border p-1 summary-input shipping-input w-full bg-white"
            required
            v-model="order.phone"
          />
        </div>
        <div class="flex flex-col my-2 lg:my-0">
          <label for="requested-date" class="text-primary text-sm font-semibold">
            Requested Delivery Date
          </label>
          <input
            type="date"
            name=""
            class="rounded border-gray-200 border p-1 summary-input"
            id="requested-date"
            v-model="order.deliveryDate"
          />
        </div>
      </div>

      <!-- Start Shipping Address -->
      <div class="border-b border-gray-300 md:border-none pb-2 md:pb-0">
        <div class="text-primary text-sm font-semibold">Address</div>
        <div class="flex flex-col my-2">
          <label for="shippingAddress" class="text-xs text-gray-500">Street</label>
          <input
            type="text"
            id="shippingAddress"
            class="rounded border-gray-200 border p-1 summary-input shipping-input bg-white"
            required
            v-model="order.shippingAddress"
          />
        </div>
        <div class="flex flex-col my-2">
          <label for="shippingCity" class="text-xs text-gray-500">City</label>
          <input
            type="text"
            id="shippingCity"
            class="rounded border-gray-200 border p-1 summary-input shipping-input bg-white"
            required
            v-model="order.shippingCity"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="flex flex-col my-2">
            <label for="shippingState" class="text-xs text-gray-500">State</label>
            <input
              type="text"
              id="shippingState"
              class="rounded border-gray-200 border p-1 summary-input shipping-input bg-white"
              required
              v-model="order.shippingState"
            />
          </div>
          <div class="flex flex-col my-2">
            <label for="shippingZip" class="text-xs text-gray-500">Zip</label>
            <input
              type="number"
              id="shippingZip"
              class="rounded border-gray-200 border p-1 summary-input shipping-input bg-white"
              required
              v-model="order.shippingZip"
            />
          </div>
        </div>
        <div>
          <label for="note" class="text-primary text-sm font-semibold">Note</label>
          <textarea
            name=""
            class="w-full rounded border-gray-200 border p-1 bg-white"
            id="note"
            rows="3"
            v-model="order.customerNote"
          ></textarea>
        </div>
      </div>
      <!-- End Address -->

      <div class="mb-2">
        <div class="text-primary text-sm font-semibold">
          Order Details <span class="text-sm text-gray-500 font-normal">(optional)</span>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="flex flex-col my-2">
            <label for="purchase-order" class="text-xs text-gray-500"> Purchase Order </label>
            <input
              type="text"
              id="purchase-order"
              class="rounded border-gray-200 border p-1 bg-white"
              v-model="order.purchaseOrder"
            />
          </div>
          <div class="flex flex-col my-2">
            <label for="job-name" class="text-xs text-gray-500"> Job Name </label>
            <input
              type="text"
              class="rounded border-gray-200 border p-1 bg-white"
              id="job-name"
              v-model="order.orderName"
            />
          </div>
        </div>
        <!-- Start Shipping Method -->

        <div class="text-primary text-sm font-semibold">Shipping Method</div>
        <div>
          <input
            type="radio"
            required
            name="shipping-method"
            id="standard"
            class="border-gray-400 border"
            value="standard"
            @change="update"
            v-model="order.shippingMethod"
          />
          <label for="standard">USPS Standard</label>
        </div>
        <div>
          <input
            type="radio"
            name="shipping-method"
            id="air"
            value="air"
            class="border-gray-400 border"
            @change="update"
            v-model="order.shippingMethod"
          />
          <label for="air">USPS Air</label>
        </div>

        <div>
          <input
            type="radio"
            name="shipping-method"
            id="ground"
            value="ground"
            class="border-gray-400 border"
            @change="update"
            v-model="order.shippingMethod"
          />
          <label for="ground">USPS Ground</label>
        </div>
        <div>
          <input
            type="radio"
            name="shipping-method"
            id="local"
            value="local"
            class="border-gray-400 border"
            @change="update"
            v-model="order.shippingMethod"
          />
          <label for="local"
            >Local Delivery <span class="text-sm text-gray-500">(Free)</span>
          </label>
        </div>
        <div>
          <input
            type="radio"
            name="shipping-method"
            id="fedex"
            class="border-gray-400 border"
            @change="update"
            value="fedex"
            v-model="order.shippingMethod"
          />
          <label for="fedex">Fedex</label>
        </div>
        <div>
          <input
            type="radio"
            name="shipping-method"
            id="call"
            class="border-gray-400 border"
            @change="update"
            value="call"
            v-model="order.shippingMethod"
          />
          <label for="call">Will Call <span class="text-sm text-gray-500">(Free)</span></label>
        </div>
        <div>
          <input
            type="radio"
            required
            name="shipping-method"
            id="dropship"
            value="Drop Ship"
            class="border-gray-400 border"
            @change="update"
            v-model="order.shippingMethod"
          />
          <label for="dropship">DropShip</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      shippingMethod: null,
    };
  },
  computed: {
    //indicates whether or not this is coming from create order.
    order: {
      get() {
        return this.$store.getters['createOrder/getOrder'];
      },
      set(val) {
        this.$store.createOrder.order = val;
      },
    },
  },
  watch: {},
  methods: {
    update() {
      this.$store.commit('order/alterKey');
    },
  },
  mounted() {},
  components: {},
};
</script>
<style scoped>
label {
  margin-bottom: 0.25rem;
}
input[type='radio'] {
  margin: 0.5rem;
}
</style>

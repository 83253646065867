<!--
This component is can have protected stuff inside of it because it is in the edit orders section and the users order section. 
-->
<template>
  <div>
    <!-- Start of the product grid -->
    <div class="grid border-b grid-cols-10 font-bold text-sm py-3 bg-primary-alt">
      <div class="col-span-5 md:mx-8">{{ title }}</div>
      <div class="flex justify-between">Price</div>
      <div class="text-center flex items-center mx-auto">Stock</div>
      <div class="flex">Quantity</div>
      <div>Line total</div>
    </div>
    <div
      v-for="item in cart"
      :key="item.varientId"
      class="grid relative py-3 border-b grid-cols-10"
    >
      <!-- Delete product button -->
      <button
        class="absolute right-1 top-0 sm:top-1 mt-2 sm:mt-1 hover:bg-red-200 cursor-pointer rounded py-1 px-2"
        @click="removeFromCart(item)"
        type="button"
      >
        <font-awesome-icon icon="times" />
      </button>

      <!-- Img -->
      <div
        class="w-12 h-12 p-2 rounded-lg shadow overflow-hidden self-center sm:mx-auto flex items-center justify-center"
      >
        <router-link :to="`/product/${item.productId}`">
          <img v-lazy="item.image" alt="" />
        </router-link>
      </div>

      <!-- Title -->
      <div class="col-span-4 row-span-1 sm:my-auto font-semibold pr-8 sm:pr-0">
        <button @click="handleNavigation(item.productId)" class="text-left">
          <div>
            {{ item.name }}
          </div>
          <div class="text-sm text-gray-600">{{ item.productNum }}</div>
        </button>
      </div>

      <div class="sm:flex justify-between items-center">
        <div class="relative">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span class="text-gray-500 sm:text-sm">$</span>
          </div>

          <input
            type="number"
            class="font-semibold w-20 border border-gray-300 rounded-md pl-6"
            v-model="item.price"
            step="any"
          />
        </div>
      </div>

      <div class="inline-flex items-center text-center px-8 text-green-300 relative tooltip">
        <div class="absolute text-xs tooltiptext whitespace-no-wrap -top-1">At Purchase</div>
        {{ item.stock ? item.stock : 0 }}
      </div>

      <!-- Quantity -->
      <div class="flex items-center justify-start mr-4 overflow-hidden">
        <div class="border rounded-md">
          <input
            type="number"
            v-model="item.quantity"
            class="w-20 text-left fulfilled-input rounded-md p-1"
          />
        </div>
        <span class="text-xs">
          {{ item.pieceSize ? `(${item.pieceSize})` : '' }}
        </span>
      </div>
      <div class="items-center inline-flex">$ {{ (item.quantity * item.price).toFixed(2) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fulfilled: { type: Boolean, default: false },
    title: { type: String, default: 'Name' },
    createOrder: { type: Boolean, default: false },
    items: { type: Array },
  },
  data: function () {
    return {};
  },
  computed: {
    cart() {
      return this.items;
    },
  },
  watch: {},
  methods: {
    handleNavigation(id) {
      const val = `/admin/products/${id}`;
      const routeData = this.$router.resolve({
        path: val,
      });
      window.open(routeData.href, '_blank');
    },
    removeFromCart(product) {
      this.$emit('removeFromCart', product);
    },
    removeScrolling() {
      const inputs = document.getElementsByClassName('fulfilled-input');
      if (inputs.length > 0) {
        for (let i = 0; i < inputs.length; i++) {
          inputs[i].addEventListener('mousewheel', function () {
            this.blur();
          });
        }
      }
    },
    alter() {
      this.$store.commit('order/alterKey');
    },
  },
  mounted() {
    this.removeScrolling();
  },
};
</script>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

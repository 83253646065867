<template>
  <div class="overflow-hidden">
    <div class="w-full relative flex cursor-pointer">
      <h4 class="text-xl">PAYMENT</h4>
    </div>
    <div class="grid gap-4 my-2 grid-cols-4 mx-2 relative">
      <!-- Start Billing Address -->
      <div
        class="border-b border-gray-300 md:border-none"
        :class="paymentType != 'Credit Card' ? 'col-span-2' : null"
      >
        <div class="text-primary text-sm font-semibold mt-4">Billing Address</div>
        <div class="flex flex-col my-2">
          <label for="billingAddress" class="text-xs text-gray-500">Street</label>
          <input
            type="text"
            id="billingAddress"
            class="rounded border-gray-200 border p-1 summary-input billing-input bg-white"
            :required="paymentType === 'Credit Card'"
            v-model="order.billingAddress"
          />
        </div>
        <div class="flex flex-col my-2">
          <label for="billingCity" class="text-xs text-gray-500">City</label>
          <input
            type="text"
            id="billingCity"
            class="rounded border-gray-200 border p-1 summary-input billing-input bg-white"
            :required="paymentType === 'Credit Card'"
            v-model="order.billingCity"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="flex flex-col my-2">
            <label for="billingState" class="text-xs text-gray-500">State</label>
            <input
              type="text"
              id="billingState"
              class="rounded border-gray-200 border p-1 summary-input billing-input bg-white"
              :required="paymentType === 'Credit Card'"
              v-model="order.billingState"
            />
          </div>
          <div class="flex flex-col my-2">
            <label for="billingZip" class="text-xs text-gray-500">Zip</label>
            <input
              type="text"
              id="billingZip"
              class="rounded border-gray-200 border p-1 summary-input billing-input bg-white"
              :required="paymentType === 'Credit Card'"
              v-model="order.billingZip"
            />
          </div>
        </div>
      </div>
      <!-- End Address -->
      <div v-if="paymentType != 'Credit Card'"></div>
      <div class="mx-auto">
        <div class="text-primary text-sm font-semibold mt-4 md:mt-0">Payment Type</div>
        <div>
          <input
            type="radio"
            required
            name="payment"
            id="credit-card"
            class="border-gray-400 border"
            value="Credit Card"
            v-model="paymentType"
          />
          <label for="credit-card" class="cursor-pointer">Credit Card</label>
        </div>
        <div>
          <input
            type="radio"
            name="payment"
            id="check"
            class="border-gray-400 border"
            value="Check"
            v-model="paymentType"
          />
          <label for="check" class="cursor-pointer">Check *</label>
        </div>
        <div>
          <input
            type="radio"
            name="payment"
            id="cash"
            value="Cash"
            class="border-gray-400 border"
            required
            v-model="paymentType"
          />
          <label for="cash" class="cursor-pointer">Cash *</label>
        </div>
        <div>
          <input
            type="radio"
            name="payment"
            id="wire"
            class="border-gray-400 border"
            value="Wire Transfer"
            v-model="paymentType"
          />
          <label for="wire" class="cursor-pointer">Wire Transfer *</label>
        </div>
        <div>
          <input
            type="radio"
            name="payment"
            id="terms"
            value="Terms"
            class="border-gray-400 border"
            v-model="paymentType"
          />
          <label for="terms" class="cursor-pointer">Terms</label>
        </div>
        <div class="mt-8 text-sm">* payment due by delivery</div>
      </div>
      <!-- Start Credit Card -->
      <div v-if="processingPayment" class="col-span-2">
        <Loading :whiteSpace="false" text="Processing payment..." />
      </div>
      <div
        id="app"
        class="text-center col-span-2 border-b border-gray-300 md:border-none"
        v-else-if="showCreditCard"
      >
        <h4>Credit Card information:</h4>
        <card class="stripe-card my-2 mx-auto" :stripe="stripeKey" />
        <div v-if="error" class="text-red">{{ error }}</div>
        <div class="flex mx-auto w-64">
          <img src="/images/static/1.png" class="h-10 mx-2" alt="" />
          <img src="/images/static/2.png" class="h-10 mx-2" alt="" />
          <img src="/images/static/3.png" class="h-10 mx-2" alt="" />
        </div>
        <div class="">
          <button
            type="button"
            class="pay-with-stripe btn-primary w-full sm:w-1/3 md:w-auto rounded-l-md my-4 mx-auto border border-primary"
            @click="pay"
          >
            Charge card
          </button>
          <StripeHandheld :order="order" @progress="progress" />
        </div>
      </div>
      <div v-else-if="creditCardCollected" class="col-span-2 text-feedbackSuccess text-center">
        <h4 class="inline-block">Credit Card is good</h4>
        <font-awesome-icon
          class="inline-block text-feedbackSuccess self-center ml-2 mb-px"
          icon="check"
        />
        <button
          v-if="!review"
          @click="creditCardCollected = !creditCardCollected"
          class="text-sm py-2 text-primary hover:text-primary-dark block md:mt-2 mx-auto"
        >
          Edit Card Info
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Card, createToken } from 'vue-stripe-elements-plus';
import keys from '@/keys.json';
import Loading from '@/components/shared/Loading';
import StripeHandheld from '@/components/admin/shared/StripeHandheld.vue';
export default {
  props: {
    review: Boolean,
  },
  data: function () {
    return {
      stripeOptions: {},
      stripeKey: keys.STRIPE_KEY,
      creditCardCollected: false,
      error: null,
      paymentType: 'Terms',
    };
  },
  computed: {
    order: {
      get() {
        return this.$store.getters['createOrder/getOrder'];
      },
      set(val) {
        this.$store.state.createOrder.order = val;
      },
    },
    showCreditCard() {
      if (this.paymentType != 'Credit Card') {
        return false;
      }
      if (this.creditCardCollected == false) {
        return true;
      }

      return false;
    },
    user() {
      return this.$store.getters['createOrder/getUser'];
    },
    delayed() {
      if (!this.user) {
        return false;
      }
      if (this.user.company.creditLimit > 0) return true;
      return false;
    },
    stripeToken: {
      get() {
        return this.$store.state.order.stripeToken;
      },
      set(val) {
        this.$store.commit('order/setToken', val);
      },
    },
    processingPayment: {
      get() {
        return this.$store.getters['createOrder/getProcessingPayment'];
      },
    },
  },
  watch: {
    paymentType() {
      this.$store.state.createOrder.order.paymentType = this.paymentType;
    },
  },
  methods: {
    async pay() {
      this.stripeOptions.name = this.order.name;
      this.stripeOptions.address_line1 = this.order.billingAddress;
      this.stripeOptions.address_city = this.order.billingCity;
      this.stripeOptions.address_state = this.order.billingState;
      this.stripeOptions.address_zip = this.order.billingZip;

      const response = await createToken(this.stripeOptions);
      if (response.token) {
        this.stripeToken = response.token;
        this.creditCardCollected = true;
      } else {
        this.error = 'Credit Card validation has failed, please use a different card.';
      }
    },
    progress() {
      this.creditCardCollected = true;
      this.order.paid = true;
      this.$emit('paymentProcessed');
    },
  },
  mounted() {},
  components: {
    Card,
    Loading,
    StripeHandheld,
  },
};
</script>
<style scoped>
.stripe-card {
  width: 300px;
  border: 1px solid grey;
}
.stripe-card.complete {
  border-color: green;
}
label {
  margin-bottom: 0.25rem;
}
label {
  margin-bottom: 0.25rem;
}
input[type='radio'] {
  margin: 0.5rem;
}
</style>

<template>
  <div class="bg-white py-8 px-4 text-center">
    <div class="my-4">
      A confirmation email has been sent to <strong>{{ order.email }}</strong>
      <span v-if="order.secondaryEmail">
        and <strong>{{ order.secondaryEmail }}</strong>
      </span>
    </div>

    <router-link :to="`/admin/orders/${order._id}`" class="my-4">
      Link to <strong>order number:</strong> #{{ order.orderNumber }}
    </router-link>

    <div class="text-center w-full">
      <img src="/images/static/order.svg" class="w-64 h-64 mx-auto" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  props: {
    order: Object,
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  components: {},
};
</script>
<style scoped></style>
